import './App.css';

function App() {
  return (
    <div className="App">
      <div className='Layout'>
        <div className='Layout__Left'></div>
        <div className='Layout__Center'>
          <div className='Menu'>
            <a className='Menu__Home'>Úvod</a>
            <a className='Menu__Blog'>Blog</a>
          </div>
          <div className='Content'>
            <h1>Včelnice Horní Sněžná</h1>

            <div className='About'>
              <div className='Vcelnice'>
                <p>Včelnice se nachází v Chráněné přírodní oblasti Šumava. <br />
                  Konkrétně na trochu obtížně dostupném místě na hranici Národního parku z jedné strany a Přírodní rezervace Prameny Blanice a také Vojenského újezdu Boletice z druhé strany.
                  Tato kombinace vytváří jedinečné místo izolované od jakékoliv lidské zemědělské činnosti. <br/>
                </p>
                <p>Včely tu mají domov na rozlehlých horských lukách na svahu hřebenu i mokřadu, které vytváří prameny Blanice. Louky obklopují nekonečné lesy, které nás izolují dokonce i od všech ostatních včelařů.</p>
                <p className='Images'><img src='vcelnice.jpg' /> <img src='ulyzima.jpg' /> </p>
              </div>
              <div className='History'>
                <p>První včelstvo jsem zde založil okolo roku 2010 z oddělku darovaného přitelem Zifčákem, nejbližším sousedem, takže dobře adaptovaný, lokální genotyp Kraňky.</p>
                <p>Můj chov není zaměřený na prodej medu, ale spíše na pozorování a odhalování fascinujících zákonistostí včelího společenství. <br/>
                Na hledání nejvhodnějších postupů a metodiky včelaření, která klade důraz na přirozený život včelstva, ale zároveň dává příležitost si "zavčelařit".<br/>
                  Stejně tak v souhlasu s životní filosofií se snažím o to, aby i můj koníček nevytvářel uhlíkovou stopu.<br/>
                  Nepoužívám syntetické materiály ani léčiva. Všechny úly a další pomůcky vyrábím sám z přírodních a recykovaných materiálů. O tom více napíšu v budoucnu.
                </p>
              </div>
            </div>
            <div className='Oddelky'>
              <h2>Oddělky 2024</h2>
              <div>
                <p>Prodej oddělků, cena 2500Kč</p>
                <p>Oddělky na 5 či více rámcích míry 39x24. Je možné objenat i pro warré úly.</p>
              </div>
            </div>
            <div className='Prodej_Medu'>
              <h2>Prodej medu 2024</h2>
              <div>Volary a okolí</div>

              <div>
                <h3>Panenský medovicový med z ekologického chovu včel v horském lesním prostředí.</h3>
                <p>Omezené množství, cena 240Kč/kg</p>
              </div>
            </div>

            <div>
              <p>Kontakt: David Štěpánek</p>
              <p>dast@seznam.cz</p>
              <p>777 818 206</p>
            </div>
          </div>
        </div>
        <div className='Layout__Right'></div>
      </div>
    </div>
  );
}

export default App;
